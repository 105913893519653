<template>
  <div class="fullScreen" :class="{ show: showEditDialog }" v-loading="loading">
    <Crumbs>
      <el-breadcrumb-item slot="after"> 审核商务信息 </el-breadcrumb-item>
    </Crumbs>
    <div class="fullScreenMain" v-loading="loading">
      <el-tabs v-model="activeName" class="fullScreenMainHeader">
        <el-tab-pane label="基本信息"></el-tab-pane>
        <el-tab-pane label="附件"></el-tab-pane>
        <el-tab-pane label="商务团队分工"></el-tab-pane>
        <el-tab-pane label="商务追踪"></el-tab-pane>
        <el-tab-pane label="商务绩效"></el-tab-pane>
      </el-tabs>

      <div class="fullScreenMainCon">
        <Manage
          style="width: 100%"
          ref="Manage"
          :BusinessId="form.id"
          :isBusinessLog="true"
          v-show="activeName == 0"
        ></Manage>
        <Annex
          :manageId="form.id"
          :file_Data="file_Data"
          @showEditDialog="showEditDialog = true"
          v-show="activeName == 1"
        ></Annex>
        <Assign style="width: 100%" :ManageId="form.id" v-show="activeName == 2"></Assign>
        <Business style="width: 100%" :BusinessId="form.id" v-show="activeName == 3"></Business>
        <DistriBution
          style="width: 100%"
          :options="{ businessManageId: form.id }"
          :Disabled="true"
          v-if="activeName == 4"
        ></DistriBution>
      </div>
      <div class="fullScreenOperation">
        <el-button type="info" @click="re">返回</el-button>
      </div>
    </div>
  </div>
</template>
<script>
import { delcommafy } from '@/util/jsencrypt'

export default {
  components: {
    Crumbs: () => import('@/components/Crumbs.vue'),
    Annex: () => import('./Annex.vue'),
    Dictionary: () => import('@/components/Dictionary.vue'),
    Assign: () => import('@/views/publicView/Assign.vue'),
    Business: () => import('@/views/publicView/Business.vue'),
    Manage: () => import('@/views/publicView/Manage.vue'),
    DistriBution: () => import('@/views/publicView/DistriBution.vue'),
  },

  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Object,
      default: function () {
        return {}
      },
    },
  },
  computed: {},

  data() {
    return {
      showEditDialog: false,
      loading: false,
      submitLoading: false,
      form: {},
      activeName: '0',
      rules: {
        // userName: [{ required: true, message: '请输入用户姓名', trigger:['blur','change'] }],
        // loginName: [{ required: true, message: '请输入登录帐号', trigger:['blur','change'] }],
        // phone: [
        //     { required: true, message: '请输入联系电话', trigger:['blur','change'] },
        //     { pattern: phone, message: '请输入正确的联系电话', trigger: ['change', 'blur']},
        // ],
      },
      deptList: [], // 部门列表
      postList: [], //岗位列表
      type: 'portrait',
      file_Data: [],
    }
  },
  inject: ['re'],
  created() {},
  watch: {
    isShow: {
      immediate: true, //初始化立即执行
      handler: function (newVal) {
        // this.showEditDialog = newVal;
      },
    },
    options: {
      immediate: true, //初始化立即执行
      deep: true, //对象深度监测
      handler: function (newVal, oldVal) {
        this.init()
      },
    },
  },
  methods: {
    Save(status) {
      this.$refs.form.validate((valid, obj) => {
        if (status == 1) {
          //通过就移交计划经营部，等待通过
          this.form.status = 3
        } else if (status == 2) {
          if (this.form.status == 1) {
            this.form.status = 21
          }
        } else if (status == 21) {
          this.form.status = 3
        }

        if (valid) {
          let obj = this.form
          this.editDialogLoading = true

          this.$api.businessManage
            .updateBusinessManageStatus(obj)
            .then(res => {
              this.editDialogLoading = false
              this.showEditDialog = false
              this.$message.success('操作成功！')
              this.re()
            })
            .catch(err => {
              this.editDialogLoading = false
              console.log(err)
            })
        } else {
          this.scrollView(obj)
        }
      })
    },
    init() {
      this.editDialogLoading = true
      if (this.options.id) {
        this.$api.businessManage
          .getBusinessManage(this.options.id)
          .then(res => {
            this.editDialogLoading = false

            let arr = []
            if (res.data.businessFileList.length > 0) {
              for (let i = 0; i < res.data.businessFileList.length; i++) {
                const e = res.data.businessFileList[i]
                e.attachmentName = e.fileName
                e.attachmentPath = e.filePath
                arr.push(e)
              }
            }
            this.file_Data = arr
            this.form = res.data.businessManage
          })
          .catch(err => {
            this.editDialogLoading = false
            console.log(err)
          })
      } else {
        this.file_Data = []
      }
    },
  },
}
</script>

<style scoped lang="scss">
// @import "@/styles/edit.scss";

@import '@/styles/config.scss';

.danwei {
  position: absolute;
  right: 2%;
}
</style>
